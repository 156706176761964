import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

const Preview = () => {
  return (
    <Layout>
      <Helmet>
        <meta name={`robots`} content={`noindex, nofollow`} />
      </Helmet>
      <div className="m-auto md:w-3/5">
        <div className="pagebuilder mb-10">
          <h2>Exhibition: Pontus Karlsson / Landscapes VII-XII</h2>
          <p className="my-5">
            Pontus Karlsson is a painter based in Sweden and a keen draughtsman
            who loves to produce drawings for future paintings an "En Plein Air"
            in remote and often hostile locations worldwide. His art is a
            mixture of analysis of composition and color contra a physical
            expressive painting style.
          </p>
          <p className="my-5 pb-3">
            This exhibition concludes the landscape series "Landscapes I-XII".
            The show is all about nature’s grandeur without man’s trace and are
            meant to reflect nature’s power and meditative side. The motifs the
            artist found in southern Spain and France.
          </p>
          <blockquote>
            To be able to simplify any subject, you need to know its inner core.
          </blockquote>
          <p className="my-5 pt-3">
            The process for creating this artwork was based on the idea of
            living in the landscape to be able to remember its particular moods.
            Spending extended time in the landscape observing and drawing is
            imperative to create the flow and directness that the artist looks
            for in the finished artwork.
          </p>
        </div>
        <div className="text-center">
          <img src="/show/stream.jpg" alt="Stream" />
          <p className="pb-10 pt-1">Stream 140x120cm</p>
        </div>
        <div className="md:w-4/5 m-auto text-center">
          <img src="/show/reservoir.jpg" alt="Reservoir" />
          <p className="pb-10 pt-1">Reservoir 120x140cm</p>
        </div>
        <div className="m-auto text-center">
          <img src="/show/cave.jpg" alt="Cave" />
          <p className="pb-10 pt-1">Cave 140x120cm</p>
        </div>
        <div className="md:w-4/5 m-auto text-center">
          <img src="/show/path.jpg" alt="Path" />
          <p className="pb-10 pt-1">Path 120x140cm</p>
        </div>
        <div className="m-auto text-center">
          <img src="/show/terasses.jpg" alt="Terasses" />
          <p className="pb-10 pt-1">Terasses 140x120cm</p>
        </div>
        <div className="md:w-4/5 m-auto text-center">
          <img src="/show/canyon.jpg" alt="Canyon" />
          <p className="pb-10 pt-1">Canyon 120x140cm</p>
        </div>
      </div>
    </Layout>
  )
}

export default Preview
